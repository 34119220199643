import { SiteApiService, SitesParams } from '@api/site/site.api.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Utils } from '@utils/Utils';
import { Site } from '../../types/Site';
import { SiteDataResult } from '../../types/DataResult';

@Injectable()
export class SitesRepositoryService {
  public constructor(private readonly siteApi: SiteApiService) {}

  public getSites(params: SitesParams): Observable<SiteDataResult> {
    if (!Utils.isNullOrUndefined(params.Issuer) && Utils.convertToBoolean(params.Issuer) === false) {
      return this.siteApi.getExternalSites(params);
    }

    return this.siteApi.getSites(params);
  }

  public getSite(
    siteID: string,
    withImage: boolean = false,
    withCompany: boolean = false,
    isExternal = false,
  ): Observable<Site> {
    if (isExternal) {
      return this.siteApi.getExternalSite(siteID, withImage, withCompany);
    }

    return this.siteApi.getSite(siteID, withImage, withCompany);
  }
}
