import { SitesParams } from '@api/site/site.api.service';
import { CompanyApiService } from '@api/company/company.api.service';
import { Injectable } from '@angular/core';
import { Utils } from '@utils/Utils';
import { Observable } from 'rxjs';
import { CompanyDataResult } from '../../types/DataResult';
import { Company } from '../../types/Company';

@Injectable()
export class CompanyRepositoryService {
  public constructor(private readonly companyApi: CompanyApiService) {}

  public getCompanies(params: SitesParams): Observable<CompanyDataResult> {
    if (Utils.isNullOrUndefined(params.Issuer) || Utils.convertToBoolean(params.Issuer)) {
      return this.companyApi.getCompanies(params);
    }

    return this.companyApi.getExternalCompanies(params);
  }

  public getCompany(companyId: string, withLogo = false, isExternal = false): Observable<Company> {
    if (isExternal) {
      return this.companyApi.getExternalCompany(companyId, withLogo);
    }

    return this.companyApi.getCompany(companyId, withLogo);
  }
}
