import { Injectable } from '@angular/core';
import { OwlDateTimeIntl } from '@danielmoncada/angular-datetime-picker';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class OwlDateTimeTranslatedIntl extends OwlDateTimeIntl {
  public constructor(private translate: TranslateService) {
    super();
    this.loadTranslation();
    this.translate.onLangChange.subscribe(() => {
      this.loadTranslation();
    });
  }

  private updateOwlDateTime(): void {
    this.upSecondLabel = this.translate.instant('general.components.date_time.up_second_label');
    this.downSecondLabel = this.translate.instant('general.components.date_time.down_second_label');
    this.upMinuteLabel = this.translate.instant('general.components.date_time.up_minute_label');
    this.downMinuteLabel = this.translate.instant('general.components.date_time.down_minute_label');
    this.upHourLabel = this.translate.instant('general.components.date_time.up_hour_label');
    this.downHourLabel = this.translate.instant('general.components.date_time.down_hour_label');
    this.prevMonthLabel = this.translate.instant('general.components.date_time.prev_month_label');
    this.nextMonthLabel = this.translate.instant('general.components.date_time.next_month_label');
    this.prevYearLabel = this.translate.instant('general.components.date_time.prev_year_label');
    this.nextYearLabel = this.translate.instant('general.components.date_time.next_year_label');
    this.switchToMonthViewLabel = this.translate.instant('general.components.date_time.switch_to_month_view_label');
    this.switchToMultiYearViewLabel = this.translate.instant(
      'general.components.date_time.switch_to_multi_year_view_label',
    );
    this.cancelBtnLabel = this.translate.instant('general.components.date_time.cancel_btn_label');
    this.setBtnLabel = this.translate.instant('general.components.date_time.set_btn_label');
    this.rangeFromLabel = this.translate.instant('general.components.date_time.range_from_label');
    this.rangeToLabel = this.translate.instant('general.components.date_time.range_to_label');
    this.hour12AMLabel = this.translate.instant('general.components.date_time.hour_12_am_label');
    this.hour12PMLabel = this.translate.instant('general.components.date_time.hour_12_pm_label');
  }

  private loadTranslation(): void {
    this.translate.onLangChange.subscribe(() => {
      this.updateOwlDateTime();
    });
  }
}
