import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SitesParams } from '@api/site/site.api.service';
import { HttpClient } from '@angular/common/http';
import { buildUrl } from '@utils/url/url.util';
import { CompanyDataResult } from '../../types/DataResult';
import { RESTServerRoute } from '../../types/Server';
import { Company } from '../../types/Company';

@Injectable()
export class CompanyApiService {
  public constructor(private httpClient: HttpClient) {}

  public getCompanies(params: SitesParams): Observable<CompanyDataResult> {
    return this.httpClient.get<CompanyDataResult>(RESTServerRoute.REST_COMPANIES, { params });
  }

  public getExternalCompanies(params: SitesParams): Observable<CompanyDataResult> {
    return this.httpClient.get<CompanyDataResult>(RESTServerRoute.REST_EXTERNAL_COMPANIES, { params });
  }

  public getCompany(companyId: string, withLogo: boolean = false): Observable<Company> {
    const params = { WithLogo: withLogo };

    return this.httpClient.get<Company>(buildUrl(RESTServerRoute.REST_COMPANY, { id: companyId }), {
      params,
    });
  }

  public getExternalCompany(companyId: string, withLogo: boolean = false): Observable<Company> {
    const params = { WithLogo: withLogo };

    return this.httpClient.get<Company>(buildUrl(RESTServerRoute.REST_EXTERNAL_COMPANIE, { id: companyId }), {
      params,
    });
  }
}
