import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { buildUrl } from '@utils/url/url.util';
import { ActionResponse, SiteDataResult } from '../../types/DataResult';
import { RESTServerRoute } from '../../types/Server';
import { Site } from '../../types/Site';

export type SitesParams = { Skip?: number; Limit?: number; SortFields?: string } & {
  [key: string]: string | number | boolean;
};

@Injectable()
export class SiteApiService {
  public constructor(private httpClient: HttpClient) {}

  public getSites(params: SitesParams): Observable<SiteDataResult> {
    return this.httpClient.get<SiteDataResult>(RESTServerRoute.REST_SITES, { params });
  }

  public getExternalSites(params: SitesParams): Observable<SiteDataResult> {
    return this.httpClient.get<SiteDataResult>(RESTServerRoute.REST_EXTERNAL_SITES, { params });
  }

  public getExternalSite(siteID: string, withImage: boolean = false, withCompany: boolean = false): Observable<Site> {
    const params = { WithImage: withImage, WithCompany: withCompany };
    return this.httpClient.get<Site>(buildUrl(RESTServerRoute.REST_EXTERNAL_SITE, { id: siteID }), {
      params,
    });
  }

  public getSite(siteID: string, withImage: boolean = false, withCompany: boolean = false): Observable<Site> {
    const params = { WithImage: withImage, WithCompany: withCompany };
    return this.httpClient.get<Site>(buildUrl(RESTServerRoute.REST_SITE, { id: siteID }), { params });
  }

  public removeUsersFromSite(siteID: string, userIDs: string[]): Observable<ActionResponse> {
    const url = buildUrl(RESTServerRoute.REST_SITE_REMOVE_USERS, { id: siteID });
    return this.httpClient.put<ActionResponse>(url, { userIDs });
  }

  public addUsersToSite(siteID: string, userIDs: string[]): Observable<ActionResponse> {
    const url = buildUrl(RESTServerRoute.REST_SITE_ADD_USERS, { id: siteID });
    return this.httpClient.put<ActionResponse>(url, { userIDs });
  }

  public updateSiteUserAdmin(siteID: string, userID: string, siteAdmin: boolean): Observable<ActionResponse> {
    const url = buildUrl(RESTServerRoute.REST_SITE_ADMIN, { id: siteID });
    return this.httpClient.put<ActionResponse>(url, { userID, siteAdmin });
  }

  public updateSiteOwner(siteID: string, userID: string, siteOwner: boolean): Observable<ActionResponse> {
    const url = buildUrl(RESTServerRoute.REST_SITE_OWNER, { id: siteID });
    return this.httpClient.put<ActionResponse>(url, { userID, siteOwner });
  }

  public assignAllUsersToSite(siteID: string): Observable<ActionResponse> {
    const url = buildUrl(RESTServerRoute.REST_SITE_ADD_ALL_USERS, { id: siteID });
    return this.httpClient.put<ActionResponse>(url, {});
  }
}
